import { Dropdown, Menu } from 'antd'
import { useCallback, useState } from 'react'
import styled from '@emotion/styled'

import * as paths from '../../../constant/path'
import Text from '../../common/Text'
import { useLogout } from '../../../services/user/user-query'
import { useTranslation } from 'react-i18next'
import Image from '../../common/Image'
import color from '../../../constant/colors'
import { BadgeStyled } from './Noti'
import { Link } from 'react-router-dom'
import { setLanguage } from '../../../utils/helper'

const BadgeForHamburger = styled(BadgeStyled)`
  .ant-badge-dot {
    top: 10%;
    padding: 4px;
  }
`

const Layout = styled.div`
  padding: 0 16px;
  margin: 0 -16px 0;
  :hover {
    cursor: pointer;
  }
`
const MenuTextStyled = styled(Text)`
  color: ${color.FONT_BLACK};
  line-height: 27px;
`

const MenuStyled = styled(Menu)`
  width: 100vw;
  padding: 16px 24px;
  margin-top: 28px;
  border-top: 1px solid #dee2e6;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
`

const MenuFeatureGroupStyled = styled(Menu.ItemGroup)`
  .ant-dropdown-menu-item-group-title {
    display: none;
  }
  .ant-dropdown-menu-item-group-list {
    display: flex;
    flex-flow: row wrap;
    margin: 0;
    > * {
      flex-basis: 50%;
    }
  }
`

const MenuItemStyled = styled(Menu.Item)`
  padding: 8px 0;
`
const MenuDividerStyled = styled(Menu.Divider)`
  margin: 8px 0;
  background-color: #dee2e6;
`

const MenuItemLanguageStyled = styled(MenuItemStyled)`
  display: flex;
  flex-flow: row;
  align-items: center;

  cursor: default !important;
  :hover {
    background-color: unset;
  }

  .icon-lang {
    cursor: pointer;
    :hover {
      box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.1);
    }
    :nth-of-type(1) {
      margin-left: auto;
      margin-right: 10px;
    }
  }
`

const LanguageIconStyle = styled(Image)`
  width: 30px;
  height: 20px;
  object-fit: cover;
`
const LogoutIconStyle = styled(Image)`
  width: 14px;
  height: 18px;
  margin-left: auto;
  object-fit: fill;
`

const Row = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`

type menuProps = {
  title: string
  path: string
  isActive?: boolean
}

type HambergerMenuProps = {
  count: number
  menuList: menuProps[]
}

const HambergerMenu = (props: HambergerMenuProps) => {
  const { count, menuList } = props

  const [menuVisible, setMenuVisible] = useState(false)
  const { mutate } = useLogout()

  const { t, i18n } = useTranslation()

  const menuItemList = [
    { title: t('app.navbar.app'), path: paths.app() },
    { title: t('app.navbar.termConditions'), path: paths.termAndCondition() },
    { title: t('app.navbar.about'), path: paths.about() },
  ]

  const closeMenu = useCallback(() => {
    setMenuVisible(false)
  }, [])

  const onLogoutClick = useCallback(() => {
    mutate()
  }, [])

  const onChangeLanguageClick = useCallback(
    (lang: 'th' | 'en') => {
      setLanguage(lang)
      i18n.changeLanguage(lang)
      closeMenu()
    },
    [closeMenu, i18n],
  )

  const onMenuClick = useCallback(() => {
    closeMenu()
  }, [closeMenu])

  const renderItem = (menu: menuProps) => (
    <MenuItemStyled onClick={onMenuClick}>
      <Link to={menu.path}>
        <MenuTextStyled size={18} type="Medium">
          {menu.title}
        </MenuTextStyled>
      </Link>
    </MenuItemStyled>
  )

  return (
    <Dropdown
      overlay={() => {
        return (
          <MenuStyled>
            <MenuFeatureGroupStyled>
              {menuList && menuList.map((menu: menuProps) => renderItem(menu))}
            </MenuFeatureGroupStyled>
            <MenuDividerStyled />
            {renderItem({
              path: paths.profile(),
              title: t('app.navbar.profile'),
            })}
            <MenuDividerStyled />
            {menuItemList.map((menu) => renderItem(menu))}
            <MenuItemLanguageStyled disabled>
              <MenuTextStyled size={18} type="Medium">
                {t('app.navbar.language')}
              </MenuTextStyled>
              <LanguageIconStyle
                className="icon-lang thai-flag"
                imageName="thaiFlag"
                onClick={() => onChangeLanguageClick('th')}
              />
              <LanguageIconStyle
                className="icon-lang usa-flag"
                imageName="usaFlag"
                onClick={() => onChangeLanguageClick('en')}
              />
            </MenuItemLanguageStyled>
            <MenuItemStyled onClick={onLogoutClick}>
              <Row>
                <MenuTextStyled size={18} type="Medium">
                  {t('app.navbar.logout')}
                </MenuTextStyled>
                <LogoutIconStyle imageName="logoutIcon" />
              </Row>
            </MenuItemStyled>
            <MenuDividerStyled />
            <MenuItemStyled
              disabled
              style={{
                paddingBottom: 0,
              }}
            >
              <MenuTextStyled size={14} type="Medium" style={{ textAlign: 'center' }}>
                {`${t('app.navbar.version')} ${'1.0'}`}
              </MenuTextStyled>
            </MenuItemStyled>
          </MenuStyled>
        )
      }}
      trigger={['click']}
      placement="bottomRight"
      visible={menuVisible}
      onVisibleChange={() => {
        setMenuVisible(!menuVisible)
      }}
    >
      <Layout>
        {!menuVisible ? (
          <BadgeForHamburger /* count={count} dot */>
            <Image imageName="hamburgerIcon" style={{ width: 28 }} />
          </BadgeForHamburger>
        ) : (
          <Image imageName="closeIcon" style={{ width: 24 }} />
        )}
      </Layout>
    </Dropdown>
  )
}

export default HambergerMenu
